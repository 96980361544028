import React,{ useState } from "react"

export default function Test() {
    const [dropdown, setDropDown] = useState(false);

    const toggleDropdown = () =>{
        setDropDown(!dropdown)
    }
    return (
        <div className="flex w-screen items-center md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
            <button type="button" onClick = {toggleDropdown} className="flex text-sm bg-gray-800 rounded-full md:me-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" id="user-menu-button" aria-expanded="false" data-dropdown-toggle="user-dropdown" data-dropdown-placement="bottom">
                <span className="sr-only">Open user menu</span>
                <img className="w-12 h-12 rounded-full" src="https://media.vogue.co.uk/photos/63611d51cf716662f02c80fb/1:1/w_2160,h_2160,c_limit/Selena_Photo%20brightened.jpg" alt="user photo" />
            </button>
            {dropdown ? 
            <div className="z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow  " id="user-dropdown">
                <div className="px-4 py-3">
                    <span className="block text-sm text-gray-900 ">Bonnie Green</span>
                    <span className="block text-sm  text-gray-500 truncate ">name@flowbite.com</span>
                </div>
                <ul className="py-2" aria-labelledby="user-menu-button">
                    <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100  ">Dashboard</a>
                    </li>
                    <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Settings</a>
                    </li>
                    <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Earnings</a>
                    </li>
                    <li>
                        <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 ">Sign out</a>
                    </li>
                </ul>
            </div>:
            <>
            </>}
            
            
        </div>
    )
}