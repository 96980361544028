import Button from "./Buttton";

export default function Title() {
    const toggleFilter = () => {
        console.log("filter is updated");
    }

    return (
        <Button className="" value="Male" selected={false} onClick={() => toggleFilter("male")}></Button>
    );
}
